<template>
  <b-card>
    <b-form-group
        abel-cols-sm="12"
        label-cols-lg="3"
        label=""
        label-for="isEnableLineAuth"
    >
      <b-input-group>
        <b-form-checkbox
            v-model="form.isEnableLineAuth"
            name="isEnableLineAuth"
            switch
        >
          เปิดใช้งาน ล็อคอินผ่านไลน์
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>
    <b-form-group
        abel-cols-sm="12"
        label-cols-lg="3"
        :label="$t('agent.line_client_id')"
        label-for="lineChannelId"
    >
      <b-input
          id="lineChannelId"
          v-model="form.lineChannelId"
          type="text"
      >
      </b-input>
    </b-form-group>
    <b-form-group
        abel-cols-sm="12"
        label-cols-lg="3"
        :label="$t('agent.line_client_secret')"
        label-for="lineChannelSecret"
    >
      <b-input
          id="lineChannelSecret"
          v-model="form.lineChannelSecret"
          type="text"
      >
      </b-input>
      <b-form-text>
        ค่าที่ใช้สามารถหาได้จาก
        <b-button
            size="sm"
            variant="link"
            href="https://developers.line.biz/console"
            target="_blank"
        >เว็บไซต์ Line developer console</b-button>
      </b-form-text>
    </b-form-group>
    <b-form-group
        v-if="callbackUrl"
        abel-cols-sm="12"
        label-cols-lg="3"
        label="Callback URL"
        label-for="callbackUrl"
    >
      <b-input-group>
        <b-input
            id="callbackUrl"
            :value="callbackUrl"
            type="text"
            readonly
        >
        </b-input>
        <b-input-group-append>
          <b-button
              v-clipboard:copy="callbackUrl"
              v-clipboard:success="onCopy"
              variant="info"
              type="button"
          >
            <i class="uil uil-file-copy-alt"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </b-card>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  page: {
    title: 'ตั้งค่าเอเย่นต์ | เข้าสู่ระบบด้วยไลน์',
  },
  props: {
    agentData: {
      type: Object,
      default: () => ({
        isEnableLineAuth: false,
        lineChannelId: '',
        lineChannelSecret: ''
      }),
    },
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    callbackUrl() {
      let baseUrl = process.env.VUE_APP_BASE_PLAYER_SITE_URL
      if(!baseUrl) return ''
      baseUrl = !baseUrl.endsWith('/') ? baseUrl + '/' : baseUrl
      return baseUrl + this.userInfo.agentDetail.prefix + '/line/callback'
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      isEnableLineAuth: this.agentData.isEnableLineAuth,
      lineChannelId: this.agentData.lineChannelId,
      lineChannelSecret: this.agentData.lineChannelSecret,
    }
  },
  methods: {
    onCopy() {
      this.$bvToast.toast('คัดลอก Callback URL เรียบร้อย', {
        variant: 'success',
        title: 'Success'
      })
    },
  }
}
</script>

<style></style>
